import styled from 'styled-components';
import typographyGenerator from 'typography/generator';

const List = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1px;
    width: 100%;
    align-self: stretch;
`;

const Icon = styled.div`
    padding-right: 7px;
    color: ${(props => props.theme?.colors.LM_N70)};
    fill: ${(props => props.theme?.colors.LM_N70)};
`;

const LabelName = styled.div`
    ${(props => typographyGenerator('body', props.theme))}
    width: calc(100% - 24px);
    color: ${(props => props.theme?.colors.LM_N70)};
`;

interface IIconLabel {
    displayName: string;
    icon: JSX.Element
};

const DisplayIconLabel: React.FC<IIconLabel> = ({ icon, displayName }) => {
    return (
        <List >
            <Icon>{icon}</Icon><LabelName>{displayName}</LabelName>
        </List>
    )
};

const RenderIcons: React.FC<any> = ({ iconLabelList }) => {
    return (
        iconLabelList.map((il: IIconLabel) => (
                <DisplayIconLabel icon={il.icon} displayName={il.displayName} key={il.displayName} />
            )
        )
    );
}

export default RenderIcons; 
