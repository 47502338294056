import React, { useMemo } from 'react';
import styled from 'styled-components';
import RenderIcons from '../DisplayIcons';
import { mapAmenities } from './amenity-type';
import Column from '../Column';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(1fr 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 32px;
    @media (min-width: ${(props => props.theme.breakpoints.medium)}) {
        grid-column-gap: 24px;
      }
`;

const Amenity = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
`;

interface IAmenitiesProps {
    amenities: string[];
}

const Amenities: React.FC<IAmenitiesProps> = ({ amenities }) => {
    const mappedAmenities = useMemo(() => mapAmenities(amenities),[amenities]);
    return (
        <Grid>
            <Column columnSpanLarge={6} columnSpanXLarge={8} columnSpanXXLarge={12} columnSpanDefault={12}>
                <Amenity>
                    <RenderIcons iconLabelList={mappedAmenities} />
                </Amenity>
            </Column>
        </Grid>
    )
}
export default Amenities;
