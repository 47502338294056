import Microwave from '../../public/icons/PropertyIcons/microwave.svg';
import Kettle from '../../public/icons/PropertyIcons/kettle-2.svg';
import Wifi from '../../public/icons/PropertyIcons/wifi.svg';
import CoffeeMachine from '../../public/icons/PropertyIcons/coffee-machine.svg';
import Garden from '../../public/icons/PropertyIcons/flowers.svg';
import Parking from '../../public/icons/PropertyIcons/parking.svg';
import Freezer from '../../public/icons/PropertyIcons/ice.svg';
import Toaster from '../../public/icons/PropertyIcons/bread.svg';
import Fridge from '../../public/icons/PropertyIcons/mini-fridge.svg';
import Hob from '../../public/icons/PropertyIcons/pot.svg';
import SwimmingPool from '../../public/icons/PropertyIcons/swimming-pool.svg';
import BBQ from '../../public/icons/PropertyIcons/bbq.svg';
import Linen from '../../public/icons/PropertyIcons/double-bed.svg';
import Towels from '../../public/icons/PropertyIcons/wallpaper.svg';
import Iron from '../../public/icons/PropertyIcons/iron.svg';
import Television from '../../public/icons/PropertyIcons/monitor.svg';
import Fireplace from '../../public/icons/PropertyIcons/fire.svg';
import Heating from '../../public/icons/PropertyIcons/gauge.svg';
import Toiletries from '../../public/icons/PropertyIcons/soap-pump.svg';
import MusicPlayer from '../../public/icons/PropertyIcons/radio.svg';
import PrivateEntrance from '../../public/icons/PropertyIcons/door.svg';
import Hairdryer from '../../public/icons/PropertyIcons/hairdryer.svg';
import Balcony from '../../public/icons/PropertyIcons/balcony.svg';
import Cot from '../../public/icons/PropertyIcons/cot.svg';
import HotTub from '../../public/icons/PropertyIcons/hot-tub.svg';
import Dishwasher from '../../public/icons/PropertyIcons/dishwasher.svg';
import Detector from '../../public/icons/PropertyIcons/detector.svg';
import Patio from '../../public/icons/PropertyIcons/picnic-bench.svg';
import Oven from '../../public/icons/PropertyIcons/oven.svg';
import WashingMachine from '../../public/icons/PropertyIcons/washing-machine.svg';
import TumbleDryer from '../../public/icons/PropertyIcons/tumble-dryer.svg';
import Highchair from '../../public/icons/PropertyIcons/highchair.svg';
import SeaView from '../../public/icons/PropertyIcons/sunrise-2.svg';
import ValleyView from '../../public/icons/PropertyIcons/trees.svg';
import MountainView from '../../public/icons/PropertyIcons/mountain.svg';
import LakeView from '../../public/icons/PropertyIcons/boat.svg';
import BeachView from '../../public/icons/PropertyIcons/beach-2.svg';
import CityView from '../../public/icons/PropertyIcons/buildings.svg';
import WheelchairAccess from '../../public/icons/PropertyIcons/disability.svg';

// Not used
// import Parasol from '../../public/icons/PropertyIcons/parasol.svg';
// import TownLocation from '../../public/icons/PropertyIcons/building-store.svg';
// import Power from '../../public/icons/PropertyIcons/plug-on.svg';

const amenityMappings: {
    [amenitySlugName: string]: {
        displayName: string,
        icon: JSX.Element
    }
} = {
    'highchair': {
        displayName: 'Highchair',
        icon: <Highchair />
    },
    'coffee maker': {
        displayName: 'Coffee maker',
        icon: <CoffeeMachine />
    },
    'wheelchair access': {
        displayName: 'Wheelchair access',
        icon: <WheelchairAccess />
    },
    'fireplace': {
        displayName: 'Fireplace',
        icon: <Fireplace />
    },
    'garden furniture': {
        displayName: 'Garden furniture',
        icon: <Patio />
    },
    'hot tub': {
        displayName: 'Hot tub',
        icon: <HotTub />
    },
    'swimming pool': {
        displayName: 'Swimming pool',
        icon: <SwimmingPool />
    },
    'balcony': {
        displayName: 'Balcony',
        icon: <Balcony />
    },
    'garden': {
        displayName: 'Garden',
        icon: <Garden />
    },
    'toiletries': {
        displayName: 'Toiletries',
        icon: <Toiletries />
    },
    'hair dryer': {
        displayName: 'Hairdryer',
        icon: <Hairdryer />
    },
    'iron': {
        displayName: 'Iron',
        icon: <Iron />
    },
    'carbon monoxide detector': {
        displayName: 'Carbon monoxide detector',
        icon: <Detector />
    },
    'smoke detector': {
        displayName: 'Smoke detector',
        icon: <Detector />
    },
    'private entrance': {
        displayName: 'Private entrance',
        icon: <PrivateEntrance />
    },
    'washing machine': {
        displayName: 'Washing machine',
        icon: <WashingMachine />
    },
    'tumble dryer': {
        displayName: 'Tumble dryer',
        icon: <TumbleDryer />
    },
    'linen': {
        displayName: 'Linen',
        icon: <Linen />
    },
    'towels': {
        displayName: 'Towels',
        icon: <Towels />
    },
    'view_garden': {
        displayName: 'Garden view',
        icon: <Garden />
    },
    'travel cot': {
        displayName: 'Travel cot',
        icon: <Cot />
    },
    'BBQ': {
        displayName: 'BBQ',
        icon: <BBQ />
    },
    'patio': {
        displayName: 'Patio',
        icon: <Patio />
    },
    'parking': {
        displayName: 'Parking',
        icon: <Parking />
    },
    'music player': {
        displayName: 'Music player',
        icon: <MusicPlayer />
    },
    'television': {
        displayName: 'Television',
        icon: <Television />
    },
    'internet': {
        displayName: 'WiFi',
        icon: <Wifi />
    },
    'heating': {
        displayName: 'Heating',
        icon: <Heating />
    },
    'dishwasher': {
        displayName: 'Dishwasher',
        icon: <Dishwasher />
    },
    'toaster': {
        displayName: 'Toaster',
        icon: <Toaster />
    },
    'kettle': {
        displayName: 'Kettle',
        icon: <Kettle />
    },
    'freezer': {
        displayName: 'Freezer',
        icon: <Freezer />
    },
    'fridge': {
        displayName: 'Fridge',
        icon: <Fridge />
    },
    'microwave': {
        displayName: 'Microwave',
        icon: <Microwave />
    },
    'hob': {
        displayName: 'Hob',
        icon: <Hob />
    },
    'oven': {
        displayName: 'Oven',
        icon: <Oven />
    },
    'air conditioning': {
        displayName: 'Air conditioning',
        icon: <Freezer />
    },
    'view_valley': {
        displayName: 'Valley view',
        icon: <ValleyView />
    },
    'view_beach': {
        displayName: 'Beach view',
        icon: <BeachView />
    },
    'view_sea': {
        displayName: 'Sea view',
        icon: <SeaView />
    },
    'view_lake': {
        displayName: 'Lake view',
        icon: <LakeView />
    },
    'view_mountain': {
        displayName: 'Mountain view',
        icon: <MountainView />
    },
    'view_city': {
        displayName: 'City view',
        icon: <CityView />
    }
};

export const mapAmenities = (amenitiesList: string[]) =>
    Array.from(new Set(amenitiesList))
        .map(amenitySlugName => amenityMappings[amenitySlugName])
        .filter(a => !!a);
