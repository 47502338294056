
import { ReactNode, useState } from 'react';
import styled from 'styled-components';

interface IWrapperProps {
  expand: boolean;
  minHeight: number;
}

const MainContentWrapper = styled.div<IWrapperProps>`
  max-height: ${({ expand, minHeight }) => expand ? 'auto' : `${minHeight}px`};
  overflow: hidden;
  line-break: strict;
  line-height: 24px;
  -webkit-line-clamp: 6;
`;

const PageSectionTitle = styled.h2`
  color: #61646B;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

const ShowMore = styled.button`
	border: none;
  position: relative;
  background-color: transparent;
  color: #1E6CF8;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

interface IPageProps {
  children: ReactNode
  title?: string
  alwaysExpand?: boolean
  minHeight?: number
}

const Wrapper: React.FC<IPageProps> = ({ children, title, alwaysExpand = false, minHeight = 190 }) => {
  const [expanded, toggleExpand] = useState(alwaysExpand);

  const onClick = (() => {
    toggleExpand(!expanded)
  });

  const isExpanded = expanded;
  return (
    <>
      <MainContentWrapper expand={isExpanded} minHeight={minHeight}>
        <PageSectionTitle>{title}</PageSectionTitle>
        {children}
      </MainContentWrapper>
      {!alwaysExpand ?
        !isExpanded
          ? <ShowMore onClick={onClick} data-test-id="SectionExpandButton">Show more</ShowMore>
          : <ShowMore onClick={onClick} data-test-id="SectionExpandButton">Show less</ShowMore>
        : null}
    </>
  )
};

export default Wrapper;
